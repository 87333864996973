<template>
  <div>
    <VueCircle
      :progress="innerProgress"
      :size="83"
      reverse
      :fill="fill"
      empty-fill="rgba(0, 0, 0, .1)"
      :animation-start-value="0.0"
      :start-angle="startAngle"
      insert-mode="append"
      :thickness="9"
      show-percent
    />
  </div>
</template>

<script>
import VueCircle from 'vue2-circle-progress';
export default {
  name: 'PercentageCircle',
  components: { VueCircle },
  props: {
    progress: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  data() {
    return {
      fill: {
        color: '#98E04C',
      },
      startAngle: -1.6,
      innerProgress: this.progress,
    };
  },

  watch: {
    progress(nv) {
      this.innerProgress = nv;
    },
  },
};
</script>

<style></style>
