<template>
  <Transition name="fade">
    <div v-if="isActive" tabindex="1" class="modal is-active">
      <div class="modal-background" @click="isActive = false"></div>
      <div class="animation-content">
        <div class="card">
          <div class="card-title">
            <span class="modal-title">
              {{ title }}
            </span>
            <button type="button" class="modal-close is-large has-label-right" @click="isActive = false"></button>
          </div>
          <div class="card-content">
            <slot>
              <span>{{ message }}</span>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    isOpened: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isActive: false,
    };
  },
  watch: {
    isOpened: {
      inmediate: true,
      handler: function(oldval) {
        this.isActive = oldval;
      },
    },
    isActive(value) {
      if (!value) this.$emit('closeTooltip');
    },
  },
  mounted() {
    this.isActive = this.isOpened;
  },
};
</script>

<style scoped>
.modal-title {
  margin: 10px auto 0;
}
.modal-close {
  position: absolute;
  top: 10px;
  margin-right: -12px;
}
.modal-background {
  background-color: transparent;
}
.card {
  background-color: rgba(0, 0, 0, 0.9);
  color: #ffffff;
  border-radius: 12px;
  max-height: 95vh;
}
.card-title {
  justify-content: center;
  display: flex;
  text-transform: uppercase;
  font-family: 'Circular-Std';
  font-size: 19px;
  padding: 35px 15px 10px;
  min-height: 55px;
}
.card-content {
  padding: 1rem 4.3rem 2rem;
  max-height: fit-content;
  white-space: pre-wrap;
  word-wrap: break-word;
  text-align: left;
  overflow: scroll;
  height: 100%;
  max-height: 70vh;
  box-sizing: content-box;
}
.card-content::-webkit-scrollbar {
  display: none;
}
.animation-content {
  width: 600px;
  padding: 2rem;
}

@media screen and (max-width: 550px) {
  .card-title {
    padding: 30px 15px 10px;
    font-size: 14px;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.7s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
@media only screen and (max-width: 768px) {
  .card-title {
    font-size: 17px;
  }
  .animation-content {
    width: 75%;
    padding: 0px;
  }
  .card-content {
    overflow: auto;
    padding: 1rem 1rem;
  }
}
@media only screen and (max-width: 560px) {
  .card-content {
    margin-top: 5px;
  }
}
@media only screen and (max-width: 420px) {
  .card-title {
    font-size: 15px;
  }
}
@media only screen and (max-width: 320px) {
  .card-title {
    font-size: 13px;
  }
}
</style>
