<template>
  <section class="section-tips-analysis tips-analysis">
    <div v-if="getPremiumAccount || isWidget" class="tips-analysis__premium-content">
      <div v-if="resetButtonVisibility" class="tips-analysis__premium-content__reset">
        <div class="tips-analysis__premium-content__reset__text">
          {{ resetProfitText }}
        </div>
        <div class="tips-analysis__premium-content__reset__button-container">
          <BButton class="reset-button" @click="resetBetsSuggested">
            <img src="/assets/icons/icn-refresh.svg" alt="Reset Button" />
          </BButton>
        </div>
      </div>
      <div
        class="tips-analysis__premium-content__simulator"
        :style="{
          'border-radius': qps ? '0' : displayWidth > 524 ? '0' : '0 0 6px 6px',
        }"
      >
        <div class="tips-analysis__premium-content__simulator__activator">
          <div>
            SIMULADOR
          </div>
          <div class="divider" />
          <div>
            <div>OFF</div>
            <div>
              <BSwitch v-model="qps" size="is-small" type="is-success"></BSwitch>
            </div>
            <div>ON</div>
          </div>
        </div>
        <div v-if="qps" class="tips-analysis__premium-content__simulator__details">
          <div class="tips-analysis__premium-content__simulator__details__description">
            Simula cuál sería tu resultado:
          </div>
          <div v-if="displayWidth > 524" class="tips-analysis__premium-content__simulator__details__data">
            <div class="header">
              Tu apuesta total
            </div>
            <div class="header">
              Tu riesgo
            </div>
            <div class="header">
              Tu monto final
            </div>
            <div class="header">
              Pérdida / Ganancia
            </div>
            <div class="data">
              <span class="monto-entero">$ {{ investmentArray[0] }}.</span>
              <span class="monto-decimal">{{ investmentArray[1] }}</span>
            </div>
            <div class="data risk-bar">
              <div class="background-progress">
                <div class="color-progress" :style="`width:${riskPercentage}%; background-color:${bgColor}`"></div>
              </div>
            </div>
            <div class="data">
              <span class="monto-entero">$ {{ totalQpsInvestArray[0] }}.</span>
              <span class="monto-decimal">{{ totalQpsInvestArray[1] }}</span>
            </div>
            <div class="data" :style="{ color: hasProfits ? '#58ab00' : '#ff0000' }">
              <span class="monto-entero">$ {{ profitOrLoss[0] }}.</span>
              <span class="monto-decimal">{{ profitOrLoss[1] }}</span>
            </div>
          </div>
          <div v-else class="tips-analysis__premium-content__simulator__details__data">
            <div class="header">
              Tu apuesta total
            </div>
            <div class="data">
              <span class="monto-entero">$ {{ investmentArray[0] }}.</span>
              <span class="monto-decimal">{{ investmentArray[1] }}</span>
            </div>
            <div class="header">
              Tu riesgo
            </div>
            <div class="data risk-bar">
              <div class="background-progress">
                <div class="color-progress" :style="`width:${riskPercentage}%; background-color:${bgColor}`"></div>
              </div>
            </div>
            <div class="header">
              Tu monto final
            </div>
            <div class="data">
              <span class="monto-entero">$ {{ totalQpsInvestArray[0] }}.</span>
              <span class="monto-decimal">{{ totalQpsInvestArray[1] }}</span>
            </div>
            <div class="header">
              Pérdida / Ganancia
            </div>
            <div class="data profit-loss" :style="{ color: hasProfits ? '#58ab00' : '#ff0000' }">
              <div>
                <span class="monto-entero">$ {{ profitOrLoss[0] }}.</span>
                <span class="monto-decimal">{{ profitOrLoss[1] }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isWidget" class="tips-analysis__premium-content__probability">
        <div class="tips-analysis__premium-content__probability__percentage">
          <div class="tips-analysis__premium-content__probability__percentage__text">
            Si apuestas de esta forma tu probabilidad de ganar por lo menos $1 peso es de:
          </div>
          <div class="tips-analysis__premium-content__probability__percentage__graph">
            <BSkeleton v-if="loading" circle width="83px" height="83px" />
            <PercentageCircle v-else :progress="premiumPercent" />
          </div>
        </div>
        <div class="tips-analysis__premium-content__probability__warning">
          <div class="tips-analysis__premium-content__probability__warning__text">
            Debes colocar las 4 apuestas de forma independiente, no son un parlay.
          </div>
          <div class="tips-analysis__premium-content__probability__warning__icon">
            <img src="/icons/icn-alert.svg" alt="Alert" />
          </div>
        </div>
        <div class="tips-analysis__premium-content__probability__explanation">
          Esta altísima probabilidad de ganar sólo aplica si apuestas en estos 4 partidos de manera simultánea
          exactamente en la forma arriba indicada.
        </div>
      </div>
    </div>
    <div v-else class="tips-analysis__free-content">
      <div class="tips-analysis__free-content__advise">
        <p>
          Te recomendamos que combines apuestas de bajo, mediano y alto riesgo (diversificación de riesgos), para que
          protejas tu dinero y puedas tener ganancias.
        </p>
      </div>
      <div class="tips-analysis__free-content__premium-description">
        <p>
          Por sólo $99 pesos al mes, los algoritmos de GolStats Premium te sugieren
          <span class="bold">la combinación perfecta</span> de apuestas de bajo, medio y alto riesgo,
          <span class="bold">y te dicen exactamente cuánto apostar en cada una</span>, para que maximices tu
          probabilidad de
          <span class="bold">proteger tu dinero y de tener ganancias.</span>
        </p>
        <p>
          Como usuario Premium, también tendrás acceso <span class="bold">a estadísticas en Vivo</span>, así como a los
          <span class="bold">filtros avanzados que utilizan los equipos profesionales.</span>
        </p>
        <div class="tips-analysis__free-content__premium-button">
          <a class="button__create-premium" @click="onClickCrearCuentaPremium">
            Crear cuenta Premium
          </a>
        </div>
      </div>
    </div>

    <MessageTooltip
      :title="tooltip.title"
      :message="tooltip.message"
      :is-opened="tooltip.isOpened"
      @closeTooltip="closeTooltip"
    />
  </section>
</template>

<script>
import MessageTooltip from '@/components/Elements/MessageTooltip.vue';
import { clickBtnQuepasariasi, clickSwtichTipsQuepasariasi, clickBtnTipsRentabilidad } from '@/utils/analytics';
import { resetProfitText } from '@/data/BetSaveProbabilityCapts';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import PercentageCircle from '@/components/Elements/charts/PercentageCircle';
import { clickCrearCuentaPremium, clickTipsRegresarARendimientoOptimo } from '@/utils/analytics';
import { BButton } from 'buefy/dist/esm/button';
import { BSwitch } from 'buefy/dist/esm/switch';
import { BSkeleton } from 'buefy/dist/esm/skeleton';

export default {
  name: 'TipsAnalysis',
  components: {
    MessageTooltip,
    PercentageCircle,
    BButton,
    BSwitch,
    BSkeleton,
  },
  props: {
    isWidget: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tooltip: {
        title: '',
        message: '',
        isOpened: false,
      },
      tooltipValues: {
        QPS: {
          title: 'SIMULADOR',
          message: 'Este simulador calcula cuál sería tu ganancia si perdieras una o varias de las 4 apuestas.',
          isOpened: true,
        },
        TGS: {
          title: 'TIP CIENTÍFICO: MAXIMIZA TU RENTABILIDAD',
          message:
            'Comúnmente se cree que entre más apuestas aciertas, mayor es tu ganancia $. ' +
            'Eso no necesariamente es verdad.\n \n' +
            'Una persona que acierta 4 de 10 apuestas puede tener más ganancias $ que alguien que acierta 8 de 10.\n' +
            'Todo depende del riesgo de la apuesta. Si le apuestas al favorito, es probable que ganes tu apuesta, ' +
            'pero tu ganancia es menor (a diferencia de apostarle al menos favorito, donde la probabilidad de ganar ' +
            'tu apuesta es menor, pero tu ganancia mayor).\n \n' +
            'GolStats Premium te sugiere la combinación exacta de apuestas de bajo, mediano y alto riesgo, y te dice ' +
            'exactamente cuánto apostar en cada una, para que maximices tu probabilidad de proteger tu dinero y de ' +
            'tener ganancias.',
          isOpened: true,
        },
      },
      qps: false,
      resetProfitText: resetProfitText,
    };
  },
  computed: {
    ...mapState('tips', ['betAmount', 'resetButtonVisibility', 'loading']),
    ...mapState(['displayWidth']),
    ...mapGetters('tips', [
      'totalInvestment',
      'returnInvestment',
      'returnQpsInvestment',
      'getUserType',
      'freePercentage',
      'premiumPercentage',
    ]),
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'getIsGolstats', 'isLoggedIn']),
    ...mapGetters('general', ['getSeccionBetsGolTipster']),
    investmentArray() {
      return this.betAmount
        ? Number(this.betAmount)
            .toFixed(2)
            .split('.')
        : ['0', '00'];
    },
    totalQpsInvestArray() {
      return this.returnQpsInvestment.split('.');
    },
    hasProfits() {
      return Number(this.returnQpsInvestment) >= this.betAmount;
    },
    profitOrLoss() {
      const [integer, float] = (Number(this.returnQpsInvestment) - Number(this.betAmount)).toFixed(2).split('.');
      return [integer || '0', float || '00'];
    },
    bgColor() {
      if (75 < this.riskPercentage) {
        return '#ff0000';
      } else if (50 < this.riskPercentage) {
        return '#f34f4f';
      } else if (25 < this.riskPercentage) {
        return '#3ee033';
      } else {
        return '#89e033';
      }
    },
    riskPercentage() {
      return Number(100 - (this.getPremiumAccount ? this.premiumPercentage : this.freePercentage));
    },
    premiumPercent() {
      return this.premiumPercentage;
    },
  },
  watch: {
    qps(value) {
      clickBtnQuepasariasi(this.getPremiumAccount, value, this.getIsGolstats);
      clickSwtichTipsQuepasariasi(this.getPremiumAccount, value, this.getIsGolstats);
      this.toggleQps(value);
    },

    premiumPercentage() {
      this.toggleLoading();
    },
    getPremiumAccount() {
      // Watch to user premium logout or login
      if (this.$route.name == 'goltipster' && this.getSeccionBetsGolTipster == 3) {
        this.toggleQps(true);
        this.qps = true;
      } else {
        this.toggleQps(false);
        this.qps = false;
      }
    },
  },
  methods: {
    ...mapMutations('tips', ['toggleQps', 'setResetButtonVisibility', 'toggleLoading']),
    ...mapActions('tips', ['resetScenarios']),
    ...mapMutations('auth', ['SET_AUTH_COMPONENT']),
    ...mapMutations('general', ['setSectionAnalytics']),
    showTooltip(option) {
      this.tooltip = this.tooltipValues[option];
    },
    closeTooltip() {
      this.tooltip = { title: '', message: '', isOpened: false };
    },
    onClickRentabilidad() {
      clickBtnTipsRentabilidad(this.getPremiumAccount, this.getIsGolstats);
      this.showTooltip('TGS');
    },
    onClickQuePasariaSi() {
      clickBtnQuepasariasi(this.getPremiumAccount, this.getIsGolstats);
      this.showTooltip('QPS');
    },
    resetBetsSuggested() {
      clickTipsRegresarARendimientoOptimo(this.getPremiumAccount, this.getIsGolstats);
      this.resetScenarios();
      this.setResetButtonVisibility(false);
      this.toggleLoading();
    },
    onClickCrearCuentaPremium() {
      this.setSectionAnalytics('btn_Tipster');
      clickCrearCuentaPremium({
        is_premium: this.getPremiumAccount,
        golstats: this.getIsGolstats,
        section: 'tips_de_golstats',
        banner: 'btn_Tipster',
      });
      this.SET_AUTH_COMPONENT('SelectPlan');
    },
  },
  mounted() {
    if (this.getSeccionBetsGolTipster == 3) {
      this.qps = true;
      this.toggleQps(true);
    } else {
      this.qps = false;
      this.toggleQps(false);
    }
  },
};
</script>

<style lang="scss" scoped>
.tips-analysis {
  &__premium-content {
    width: 100%;

    &__reset {
      border: solid 2px #ccd8eb;
      border-bottom: none;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 30px 20px;

      &__button-container {
        margin-left: 20px;
      }
    }

    &__simulator {
      border: solid 2px #ccd8eb;

      &__activator {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-family: Avenir-Demi, sans-serif;
        font-size: 12px;
        padding: 12px 50px;
        color: #6b6b6b;

        & > div {
          margin: 0 16px;
        }

        & > .divider {
          border-left: 1px dotted #979797;
          height: 1rem;
        }

        & div:last-child {
          display: flex;
          justify-content: center;
          align-items: center;

          & .switch {
            margin-left: 12px;
          }
        }
      }

      &__details {
        &__description {
          font-family: Circular-Std, sans-serif;
          font-size: 22px;
          font-weight: bold;
          color: white;
          background-color: #12063c;
          padding: 10px;
        }

        &__data {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          grid-gap: 1px;
          border-top: solid 2px white;

          & .header {
            color: white;
            background-color: #6889bf;
            padding: 10px;
          }

          & .data {
            color: #3c3c3c;
            background-color: #f1f1f1;
            padding: 10px 30px;

            &.risk-bar {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }

    &__probability {
      margin-top: 20px;
      padding: 18px;
      border-radius: 5px;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.11);
      border: solid 1px #dddddd;
      background-color: #ffffff;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 40px;
      font-family: Avenir-Regular, sans-serif;

      &__percentage {
        display: flex;
        align-items: center;

        &__text {
          padding: 30px 25px;
          background-color: #eef8e7;
          border-radius: 11px;
          text-align: left;
        }

        &__graph {
          margin-left: 16px;
        }
      }

      &__warning {
        display: flex;
        align-items: center;

        &__text {
          padding: 30px 25px;
          background-color: #f5bcbc;
          border-radius: 11px;
          text-align: left;
        }

        &__icon {
          min-width: 78px;
          height: auto;
          margin: 15px;
          & > img {
            width: 100%;
          }
        }
      }

      &__explanation {
        padding: 20px 40px 0;
        text-align: center;
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }
  }

  &__free-content {
    font-family: Avenir-Regular, sans-serif;
    text-align: left;
    color: #292929;

    &__advise {
      padding: 12px;
    }

    &__premium-description {
      margin-top: 16px;
      padding: 30px 25px;
      background-color: #eef8e7;
      border-radius: 11px;

      & .bold {
        font-family: Avenir-Pro-Bold, sans-serif;
      }
    }

    &__premium-button {
      width: 100%;
      margin: 30px 0 8px;
    }
  }
}
.button__create-premium {
  display: block;
  margin: 0 auto;
  width: fit-content;
  color: #93b87a;
  padding: 8px 34px;
  border-radius: 28px;
  border: solid 3px #93b87a;
  font-family: Circular-Std, sans-serif;
  font-size: 24px;
  cursor: pointer;
}

.reset-button {
  width: 58px;
  height: 40px;
  padding: 5px 13px 0;
  border-radius: 5px;
  border-width: 0;
  background-image: linear-gradient(to bottom, #7ed321, #498902), linear-gradient(to bottom, #7ed321, #7ed321);

  & img {
    width: 25px;
    height: 25px;
    object-fit: contain;
  }
}

@media only screen and (max-width: 662px) {
  .tips-analysis {
    &__premium-content {
      &__probability {
        grid-template-columns: 1fr;
        padding: 4px;
        border-radius: 0;
        box-shadow: none;
        border: none;
        font-size: 12px;

        &__percentage {
          order: 1;

          &__text {
            padding: 20px 16px;
          }
        }

        &__warning {
          flex-direction: row-reverse;
          order: 3;

          &__text {
            padding: 20px 16px;
          }

          &__icon {
            min-width: 58px;
            margin-left: 0;
          }
        }

        &__explanation {
          order: 2;
          text-align: left;
          grid-column-start: 1;
          grid-column-end: 2;
          padding: 20px 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 524px) {
  .tips-analysis {
    &__premium-content {
      &__reset {
        padding: 18px 14px;
        &__text {
          text-align: left;
          font-size: 13px;
        }
      }

      &__simulator {
        &__activator {
          font-size: 9px;
          justify-content: center;
          padding: 8px 0;
        }

        &__details {
          &__description {
            font-size: 15px;
          }

          &__data {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 1px;
            border-top: solid 2px white;

            & .header {
              display: flex;
              align-items: center;
              font-size: 14px;
              text-align: left;
            }

            & .data {
              &.risk-bar {
              }
              &.profit-loss {
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
        }
      }
    }

    &__free-content {
      font-size: 13px;
      &__advise {
      }

      &__premium-description {
        padding: 15px;
      }

      &__premium-button {
        margin: 15px 0 4px;
      }
    }
  }

  .button__create-premium {
    padding: 8px 29px;
    font-size: 18px;
  }
}
.background-progress {
  background-color: #aaaaaa;
  position: relative;
  border-radius: 8px;
  padding: 4px 0px;
  width: 100%;
}
.color-progress {
  background-color: red;
  position: absolute;
  border-radius: 8px;
  padding: 4px 0px;
  top: 0px;
  left: 0px;
  width: 50%;
  transition: all 0.8s cubic-bezier(0.07, 1.11, 1, 1);
}
.monto-entero {
  width: 72px;
  height: 24px;
  font-family: 'Circular-Std';
  font-size: 19px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}
.monto-decimal {
  font-size: 10px;
  font-weight: 500;
  letter-spacing: -0.03px;
}
</style>
